.Owner {
    font-weight: bold;
    color: #2142b2;
    margin-right: 8px;
  }
  
  .Date {
    font-weight: lighter;
    color: #c1c1c5;
  }
