.NavBar {
    background-color: #ffffff;
    border: 1px solid #dadadf;
  }
  
  .NavLink {
    padding: 5px;
  }
  
  .Active i {
    color: #2142b2;
  }